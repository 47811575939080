<template>

    <div>
        <div class="modal-backdrop fade show"></div>

        <!-- Custom field modal -->
        <div class="modal custom-fields show" id="update-user-modal" style="display: block;">
            <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
                <form action="">
                    <div class="modal-content" v-on-click-away="closeModal">
                        <div class="modal-header">
                            <h4 class="modal-title text-uppercase">{{ $t("configure")}}: {{data.name}}</h4>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click.prevent="closeModal()">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="form-note">
                                {{ $t('From') }}:<code class="modal-body-code" style="padding-left: 2px;" >{{data.sender}}</code>
                            </div>
                            <div class="form-group">
                                <input type="text" id="email_subject" class="form-control" v-model="form.subject" placeholder="Type subject here" ref="email_subject">
                            </div>
                            <div class="form-group">
                                <div class="editor__wrapper ej-ck-editor" id="ck-email-body">
                                    <ckeditor
                                            :editor="editor"
                                            :config="editorConfig"
                                            v-model="content"
                                            @ready="onEBReady">
                                    </ckeditor>
                                </div>
                            </div>
                            <div class="form-note" v-if="data.placeholders.length">
                                <span class="text-capitalize">{{$t('placeholders')}}: </span>
                                <code @click="copyToClipboard('['+placeholder+']')" class="ml-1" v-for = 'placeholder in data.placeholders'>{{ placeholder }} <span class="eicon e-duplicate"></span></code>
                            </div>
                            <div class="form-note">
                                <span class="text-capitalize">{{$t('tips')}}: </span>
                                {{$t('To use placeholder put them inside [], ex: [PLACEHOLDER_NAME].')}}
                            </div>
                        </div>
                        <div class="modal-footer d-flex justify-content-between">
                            <a class="button semi-button-info" href="#" @click.prevent="closeModal()">{{ $t("Back")}}</a>
                            <submit-button :click="updateSetting" :loading="isLoading">{{ $t("Update")}}</submit-button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

</template>

<script>
    import client from "../../../app/api/Client";
    import {ckEditorBasicOptions} from "../../../config/options";
    import CKEditor from "@ckeditor/ckeditor5-vue2";
    import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";

    export default {
        props: ['data'],
        components: {
            ckeditor: CKEditor.component
        },
        data() {
            return {
                editor: DecoupledEditor,
                form: {
                    content: '',
                    subject: '',
                },
                errors: {
                    content: '',
                    subject: '',
                },
                isLoading: false,
                content: ''
            }
        },
        computed: {
            editorConfig() {
                let placeholderConfig = {'placeholder': this.$t(`Insert text here.`) };
                return {...ckEditorBasicOptions, ...placeholderConfig};
            }
        },
        methods: {
            closeModal() {
                this.$emit('closeUpdateModal');
            },
            async updateSetting() {
                this.isLoading = true;
                try {
                    this.form.content= this.content;
                    let {data: {message}} = await client().put(`/company/setting/email/${this.data.id}`, this.form);
                    this.$toast.success(this.$t(message));
                    this.$emit('setupUpdated');
                    this.closeModal();
                } catch (error) {
                    this.$toast.error(error.response.data.message);
                }
                this.isLoading = false;
            },
            onEBReady(editor) {
                const toolbarContainer = document.querySelector('#ck-email-body');
                toolbarContainer.prepend(editor.ui.view.toolbar.element);
                this.content = this.form.content;
            }
        },
        mounted() {
            this.form.content = this.data.content;
            this.form.subject = this.data.subject;
            this.$nextTick(()=>{
                this.$refs.email_subject.focus();
            });
        }
    }
</script>

<style scoped>
code {
    border: 1px solid;
    padding: 5px 10px;
    border-radius: 20px;
    margin: 6px 0;
    display: inline-block;
}
code:hover {
    cursor: pointer;
}
.modal-body-code {
    border: 0;
}
.modal-body-code:hover {
    cursor: default;
}

</style>
